import { AvmPostResponse } from "../types/AvmPostResponse";
import { AvmGetResponse } from "../types/AvmGetResponse";
import apiClient from "../types/ApiClient";


export const reserveAccounts = async (accountsInfo): Promise<AvmPostResponse> => {
    try {
        const response = await apiClient.post(`/reserve-accounts`, accountsInfo, {
            headers: {
                "Content-Type": "application/json",
            },
        });
        return response.data;
    } catch (error) {
        return error.response.data;
    }
};

export const getAccount = async (rsrvid?: string, gspAccount?: string, currency?: string, usageCode?: string): Promise<AvmGetResponse> => {
    try {
        let url = `/reservations`;

        const params = new URLSearchParams();
        if (rsrvid) params.append('rsrvid', rsrvid);
        if (gspAccount) params.append('gspAccount', gspAccount);
        if (currency) params.append('currency', currency);
        if (usageCode) params.append('usageCode', usageCode);

        if (params.toString()) {
            url += `?${params.toString()}`;
        }

        const response = await apiClient.get(url);
        return response.data;
    } catch (error) {
        return error.response.data;
    }
};
