import { Form } from "@formio/react";
import avmForm from '../../assets/avm-reservation-no-api.json';
import "../../styles/form-renderer.css";
import "../../styles/standard.css";
import "../../styles/loading-dialogue.css";
import { reserveAccounts, getAccount } from "../../services/Avm-Reservation-Service";
import { Container, Col, Row, Button } from "react-bootstrap";
import React, { useState } from "react";
import AvmReservationModel from "../models/Avm-Reservation-Model";
import {
    Dialog,
    DialogActions,
    DialogContent,
    DialogContentText,
    DialogTitle,
    CircularProgress
} from "@material-ui/core";
import * as XLSX from 'xlsx';
import { AvmGetResponse } from "../../types/AvmGetResponse";
import { AvmPostResponse } from "../../types/AvmPostResponse";
import {isSuccessfulResponse} from "../../utils/isSuccessfulResponse";
import '@fortawesome/fontawesome-free/css/all.min.css';

let accountNumberList = [];

function AvmForm() {
    const [avmResponseTitle, setAvmResponseTitle] = useState(null);
    const [avmResponseDescription, setAvmResponseDescription] = useState(null);
    const [openRequestDialog, setOpenRequestDialog] = useState(null);
    const [openErrorDialog, setErrorDialog] = useState(null);
    const [errorMessage, setErrorMessage] = useState(null);
    const [formDefinition, setFormDefinition] = useState(avmForm);
    const [loading, setLoading] = useState(false);

    const onFormChange = (event) => {

        // Remove results table upon request type change
        if (event && event.data && event.data.requestType) {
            const updatedForm = { ...formDefinition };

            accountNumberList = [];

            const dataGridComponent1 = updatedForm.components.find(component => component.key === "resultsGrid1");
            const dataGridComponent2 = updatedForm.components.find(component => component.key === "resultsGrid2");
            dataGridComponent1.hidden = true;
            dataGridComponent2.hidden = true;

            const excelButtonComponent1 = updatedForm.components.find(component => component.key === "exportToExcel1");
            const excelButtonComponent2 = updatedForm.components.find(component => component.key === "exportToExcel2");
            excelButtonComponent1.hidden = true;
            excelButtonComponent2.hidden = true;

            const requestTypeComponent = updatedForm.components.find(component => component.key === "requestType");

            if (event.data.requestType === 'GSP') {
                requestTypeComponent.defaultValue = 'GSP';
            } else if (event.data.requestType === 'IMMS') {
                requestTypeComponent.defaultValue = 'IMMS';
            } else if (event.data.requestType === 'BOTH') {
                requestTypeComponent.defaultValue = 'BOTH';
            }
            setFormDefinition(updatedForm);
        }
    };

    const handleGet = async (rsrvid?: string) => {
        try {
            const response: AvmGetResponse = await getAccount(rsrvid);

            const gspAccountData = response.data.gspAccountsData;
            const cashAccountData = response.data.cashAccountsData;

            let updatedForm = { ...formDefinition };

            const requestTypeComponent = updatedForm.components.find(component => component.key === "requestType");
            requestTypeComponent.defaultValue = '';

            if (gspAccountData && gspAccountData.length > 0 && (!cashAccountData || cashAccountData.length == 0)) {
                // Reset results between submits
                const componentName = "resultsGrid1"
                const dataGridComponent = updatedForm.components.find(component => component.key === componentName);
                dataGridComponent.defaultValue = [];

                gspAccountData.forEach(account => {
                    accountNumberList.push({
                        rsrvid: rsrvid,
                        cashAccountNumber: account.gspAccount,
                        status: account.status
                    });
                });

                dataGridComponent.label = "Reservation Id: " + rsrvid
                dataGridComponent.defaultValue = accountNumberList;
                dataGridComponent.hidden = false;

                const excelButtonComponent1 = updatedForm.components.find(component => component.key === "exportToExcel1");
                excelButtonComponent1.hidden = false;

            } if (cashAccountData && cashAccountData.length > 0) {
                // Reset results between submits
                const componentName = "resultsGrid2"
                const dataGridComponent = updatedForm.components.find(component => component.key === componentName);
                dataGridComponent.defaultValue = [];

                const requestTypeComponent = updatedForm.components.find(component => component.key === "requestType");
                requestTypeComponent.defaultValue = '';

                cashAccountData.forEach(account => {
                    accountNumberList.push({
                        rsrvid: rsrvid,
                        cashAccountNumber: account.gspAccount,
                        accountNumber: account.accountNumber,
                        status: account.status,
                        currencyCode: account.currencyCode,
                        usageCode: account.usageCode
                    });
                });

                dataGridComponent.label = "Reservation Id: " + rsrvid
                dataGridComponent.defaultValue = accountNumberList;
                dataGridComponent.hidden = false;

                const excelButtonComponent2 = updatedForm.components.find(component => component.key === "exportToExcel2");
                excelButtonComponent2.hidden = false;
            }

            setFormDefinition(updatedForm);

            return response;
        } catch (error) {
            setLoading(false);
            setErrorMessage(error.toString());
            setErrorDialog(true);
        }
    };

    function transformUsages(formData: any) {
        if (formData.multifield && Array.isArray(formData.multifield)) {
            formData.multifield.forEach((field: any) => {
                if (field.usages && Array.isArray(field.usages)) {
                    field.usages = field.usages.map((usage: any) => usage.value);
                }
            });
        }
        return formData;
    }

    const submitForm = async (formData) => {
        setLoading(true);
        try {
            transformUsages(formData);
            const response: AvmPostResponse = await reserveAccounts(formData.toJson());
            setLoading(false);
            setAvmResponseTitle(isSuccessfulResponse(response) ? "Success" : "Failure");
            setAvmResponseDescription(response.metadata.description);
            setOpenRequestDialog(true);
            return response;
        } catch (error) {
            setLoading(false);
            setErrorMessage(error.toString());
            setErrorDialog(true);
        }
    };

    const handleSubmitForm = async (submission) => {
        try {
            const submittedForm = new AvmReservationModel(submission.data);

            if (submittedForm.validate() === null) {
                const submitResponse = await submitForm(submittedForm);
                const submitRsrvid = submitResponse.data.rsrvid;
                await handleGet(submitRsrvid);
            }
        } catch (error) {
            setErrorMessage(error.toString());
            setErrorDialog(true);
        }
    };

    const handleCustomEvent = (event) => {
        try {
            const currentDate = new Date().toISOString().split('T')[0]; // current date YYYY-MM-DD

            if (event.component.key === 'exportToExcel1') {
                const dataWithHeaders = [
                    ...accountNumberList.map(account => ({
                        'Reservation Id': account.rsrvid,
                        'Custody Account Number': account.cashAccountNumber,
                        'Status': account.status || ''
                    }))
                ];

                const worksheet = XLSX.utils.json_to_sheet(dataWithHeaders);
                const workbook = {
                    Sheets: { 'data': worksheet },
                    SheetNames: ['data']
                };
                XLSX.writeFile(workbook, `GSP_Accounts_List_${currentDate}.xlsx`);

            } else if (event.component.key === 'exportToExcel2') {
                const dataWithHeaders = [
                    ...accountNumberList.map(account => ({
                        'Reservation Id': account.rsrvid,
                        'Custody Account Number': account.cashAccountNumber,
                        'Cash Account Number': account.accountNumber,
                        'Status': account.status,
                        'Currency Code': account.currencyCode,
                        'Usage Code': account.usageCode || ''
                    }))
                ];

                const worksheet = XLSX.utils.json_to_sheet(dataWithHeaders);
                const workbook = {
                    Sheets: { 'data': worksheet },
                    SheetNames: ['data']
                };
                XLSX.writeFile(workbook, `GSP_Accounts_List_${currentDate}.xlsx`);
            }
        } catch (error) {
            setErrorMessage(error.toString());
            setErrorDialog(true);
        }
    };

    const handleCloseRequestDialog = () => {
        setOpenRequestDialog(false);
    };

    const handleCloseErrorDialog = () => {
        setErrorDialog(false);
    };

    return (
        <Container>
            <Row>
                <Col>
                    <h2 className="page-headers">Account Reservation Form</h2>
                    <div className="main">
                        <Form
                            form={formDefinition}
                            onChange={onFormChange}
                            onSubmit={handleSubmitForm}
                            onCustomEvent={handleCustomEvent}
                        />
                    </div>
                </Col>
            </Row>
            <React.Fragment>
                <Dialog
                    open={openRequestDialog}
                    keepMounted
                    onClose={handleCloseRequestDialog}
                >
                    <DialogTitle>{avmResponseTitle}</DialogTitle>
                    <DialogContent>
                        <DialogContentText id="alert-dialog-slide-description">
                            Your attempt to create an account reservation was a {avmResponseTitle} -{" "}
                            {avmResponseDescription}
                        </DialogContentText>
                    </DialogContent>
                    <DialogActions>
                        <Button onClick={handleCloseRequestDialog}>Close</Button>
                    </DialogActions>
                </Dialog>
            </React.Fragment>
            <React.Fragment>
                <Dialog
                    open={openErrorDialog}
                    keepMounted
                    onClose={handleCloseErrorDialog}
                >
                    <DialogTitle>Error</DialogTitle>
                    <DialogContent>
                        <DialogContentText id="alert-dialog-slide-description">
                            Oops something went wrong! {errorMessage}
                        </DialogContentText>
                    </DialogContent>
                    <DialogActions>
                        <Button onClick={handleCloseErrorDialog}>Close</Button>
                    </DialogActions>
                </Dialog>
            </React.Fragment>
            <React.Fragment>
                <Dialog
                    open={loading}
                    keepMounted
                    maxWidth={'xs'}
                    fullWidth={true}
                >
                    <DialogContent className="dialog-content-centered">
                        <CircularProgress size={70}/>
                        <DialogContentText className="loading-text">
                            Loading...
                        </DialogContentText>
                    </DialogContent>
                </Dialog>
            </React.Fragment>
        </Container>
    );
}

export default AvmForm;